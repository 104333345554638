@import "./fonts.scss";
@import "./themify-icons.css";
@import "~bootstrap/scss/bootstrap.scss";
@import "../fonts/Fontawesome/css/all.css";

.portfolio {
  margin: 0;
  font-family: "Rubik", sans-serif;
  line-height: 1.7;
  font-size: 15px;
  text-align: left;
  background-color: #fff;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.8);
  overflow-x: hidden;
  &.theme-light {
    background: #fff;
    color: #555;
    font-weight: 400;
  }
}

.m-15px-tb {
  margin-top: 15px;
  padding-bottom: 15px;
}

img {
  max-width: 100%;
}
a {
  &:hover {
    text-decoration: none;
  }
}

.aducation-box {
  margin: 0;
  padding: 0;
  background: #0b0b13;
  list-style: none;
  li {
    padding: 20px 20px;
  }
  span {
    background: #2fbf71;
    color: #fff;
    font-size: 11px;
    padding: 1px 8px;
    display: inline-block;
    vertical-align: top;
    letter-spacing: 1px;
    margin-left: -24px;
    position: relative;
    margin-bottom: 9px;
  }
  h6 {
    color: #fff;
    margin: 5px 0;
  }
  p {
    margin: 0;
  }
}

.px-btn {
  padding: 0 25px;
  line-height: 42px;
  position: relative;
  display: inline-block;
  background: none;
  border: 1px solid transparent;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 2px;
  text-decoration: none !important;
  transition: ease all 0.35s;
  &.px-btn-theme {
    background: #2fbf71;
    color: #fff;
    box-shadow: 4px 4px #fff;
  }
}

.theme-light {
  .sidebar {
    background: #f7f7ff;
    .nav-menu {
      li {
        &.active {
          a {
            font-weight: 600;
            color: #2fbf71;
          }
        }
        .nav-link {
          color: rgba(11, 11, 19, 0.7);
          font-weight: 400;
        }
      }
    }
    .h1-top {
      h5 {
        color: #0b0b13;
      }
    }
    .social-icons {
      a {
        color: #0b0b13 !important;
      }
    }
  }
  .pp-section {
    background: #fff !important;
  }
  .color-switch {
    background: #0b0b13;
    color: #fff;
  }
  .home-banner {
    .type-box {
      h6 {
        color: #0b0b13 !important;
      }
      h1 {
        color: #0b0b13 !important;
      }
      .lead {
        color: #0b0b13 !important;
      }
    }
    &::after {
      background: #f7f7ff !important;
    }
  }
  .px-btn {
    &.px-btn-theme {
      box-shadow: 4px 4px #0b0b13 !important;
    }
  }
  .title {
    h3 {
      color: #0b0b13 !important;
    }
  }
  .about-info {
    .about-text {
      h3 {
        color: #0b0b13 !important;
      }
    }
  }
  .separated {
    &::after {
      background: #0b0b13 !important;
    }
  }
  .aducation-box {
    background: #f7f7ff;
    h6 {
      color: #0b0b13 !important;
    }
  }
  .skills-box {
    h3 {
      color: #0b0b13 !important;
    }
    .skill-lt {
      h6 {
        color: #0b0b13 !important;
      }
    }
  }
  .resume-row {
    h6 {
      color: #0b0b13 !important;
    }
  }
  .feature-box-01 {
    background: #f7f7ff !important;
    .icon {
      color: #0b0b13 !important;
    }
  }
  .blog-grid {
    .blog-info {
      background: #f7f7ff !important;
      a {
        color: #0b0b13 !important;
        background-image: linear-gradient(130deg, #0b0b13, #0b0b13) !important;
      }
    }
  }
  .contact-info {
    background: #f7f7ff !important;
    h4 {
      color: #0b0b13 !important;
    }
    li {
      color: #0b0b13 !important;
    }
  }
}

.navbar {
  padding: 15px;
  display: none;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 11;
  background: #10101a;
  .navbar-brand {
    padding: 0;
    display: inline-block;
    margin-right: 1rem;
    font-size: 1.25rem;
    line-height: inherit;
    white-space: nowrap;
  }
  .logo-text {
    font-weight: 600;
    color: #fff;
    font-size: 25px;
    line-height: 1;
    text-decoration: none;
    background-color: transparent;
  }
  .toggler-menu {
    width: 40px;
    height: 40px;
    position: relative;
    margin: 0;
    border-radius: 0;
    padding: 0;
    background: #2fbf71;
    border: none;
    margin-left: auto;
    span {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 25px;
      height: 2px;
      margin: auto;
      box-shadow: 0px -8px 0 0px currentColor, 0px 8px 0 0px currentColor;
      background: #fff;
      color: #fff;
    }
  }
}

.sidebar {
  width: 250px;
  position: fixed;
  bottom: 0;
  top: 0;
  background: #0c0c14;
  .scroll-bar {
    height: calc(100% - 50px);
  }
  .h1-top {
    text-align: center;
    position: relative;
    padding: 65px 25px 25px;
    .img {
      width: 100px;
      height: 100px;
      overflow: hidden;
      display: inline-block;
      vertical-align: top;
      border-radius: 50%;
      border: 5px solid #fff;
    }
    h5 {
      margin: 15px 0 0;
      font-size: 14px;
      letter-spacing: 1px;
      color: #fff;
      font-weight: 400;
      text-transform: uppercase;
    }
    &::after {
      content: "";
      width: 310px;
      height: 310px;
      position: absolute;
      top: -180px;
      left: -50px;
      right: 0;
      bottom: 0;
      z-index: -1;
      border-radius: 0 0 50% 50%;
      background: #2fbf71;
    }
  }
  .nav-menu {
    padding-top: 45px;
    padding-bottom: 45px;
    li {
      width: 100%;
      &.active {
        a {
          color: #2fbf71;
          font-weight: 600;
        }
      }
      .nav-link {
        margin: 0;
        padding: 15px 30px;
        color: rgba(255, 255, 255, 0.8);
        text-decoration: none;
        transition: ease all 0.35s;
        font-size: 12px;
        font-weight: 300;
        letter-spacing: 3px;
        text-transform: uppercase;
        line-height: 18px;
        i {
          font-size: 18px;
          font-weight: 300;
          display: inline-block;
          vertical-align: middle;
        }
        span {
          display: inline-block;
          vertical-align: middle;
          margin-left: 10px;
        }
      }
    }
  }
  .social-icons {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 12px 0;
    a {
      color: #fff;
      font-size: 14px;
      margin-right: 17px;
    }
  }
}

.pp-section {
  background: #10101a;
}

.pp-scrollable {
  overflow: auto;
}

.section {
  padding: 100px 0;
}

.home {
  .home-banner {
    overflow: hidden;
    position: relative;
    .container {
      position: relative;
      z-index: 1;
    }
    .full-screen {
      min-height: 100vh;
      padding-top: 100px;
      padding-bottom: 100px;
    }
    .type-box {
      padding-top: 40px;
      padding-bottom: 40px;
      h6 {
        color: #fff;
        font-size: 25px;
        font-weight: 500;
        margin: 0 0 20px;
      }
      h1 {
        font-size: 44px;
        font-weight: 700;
        line-height: 1;
        margin: 0 0 20px;
        color: #fff;
      }
      .lead {
        color: #fff;
        font-weight: 500;
        font-size: 18px;
        margin-bottom: 25px;
      }
      .desc {
        max-width: 450px;
        font-size: 18px;
      }
      .btn-bar {
        padding-top: 15px;
      }
    }
    &::after {
      content: "";
      position: absolute;
      right: -20vh;
      top: -20vh;
      width: 100vh;
      height: 100vh;
      border-radius: 50%;
      background: #0b0b13;
      z-index: 0;
    }
  }
}

.title {
  margin-bottom: 35px;
  h3 {
    color: #fff;
    margin: 0;
    position: relative;
    z-index: 1;
    font-weight: 300;
    font-size: 30px;
    display: inline-block;
    vertical-align: top;
    text-transform: uppercase;
    letter-spacing: 6px;
    &::before {
      content: "";
      position: absolute;
      bottom: -5px;
      left: 0;
      right: 0;
      width: 60px;
      background: #2fbf71;
      height: 1px;
    }
    &::after {
      content: "";
      width: 6px;
      height: 6px;
      border-radius: 50%;
      position: absolute;
      bottom: -7px;
      left: 56px;
      right: 0;
      z-index: -1;
      background: #2fbf71;
    }
  }
}

.about {
  .about-info {
    .title {
      margin-bottom: 40px;
      h3 {
        color: #fff;
        margin: 0;
        position: relative;
        z-index: 1;
        font-weight: 300;
        font-size: 30px;
        display: inline-block;
        vertical-align: top;
        text-transform: uppercase;
        letter-spacing: 6px;
        &::before {
          content: "";
          position: absolute;
          bottom: -5px;
          left: 0;
          right: 0;
          width: 60px;
          background: #2fbf71;
          height: 1px;
        }
        &::after {
          content: "";
          width: 6px;
          height: 6px;
          border-radius: 50%;
          position: absolute;
          bottom: -7px;
          left: 56px;
          right: 0;
          z-index: -1;
          background: #2fbf71;
        }
      }
    }
    .about-text {
      h3 {
        color: #fff;
        margin-bottom: 25px;
      }
    }
  }
  .skills-box {
    h3 {
      color: #fff;
      margin: 0 0 15px;
    }
    p {
      padding-bottom: 10px;
    }
    .skill-lt {
      &:not(:first-child) {
        margin-top: 15px;
      }
      h6 {
        font-size: 14px;
        margin: 0 0 10px;
        font-weight: 400;
        color: #fff;
      }
    }
  }
  .resume-box {
    padding: 35px;
    .resume-row {
      padding: 35px;
      .rb-left {
        min-height: 100%;
        text-align: center;
      }
      .rb-right {
        p {
          margin-bottom: 0;
          max-width: 80%;
        }
      }
      h6 {
        margin-bottom: 5px;
        color: #fff;
        font-weight: 500;
        font-size: 20px;
      }
      label {
        font-size: 13px;
        margin-bottom: 12px;
      }
      .rb-time {
        display: inline-block;
        text-align: center;
        padding: 5px 0;
        width: 85px;
        color: #fff;
        background: #2fbf71;
        font-size: 10px;
        font-weight: 300;
        text-transform: uppercase;
        letter-spacing: 2px;
        position: absolute;
        top: 0;
        right: 0;
      }
    }
  }
}

.services {
  .feature-box-01 {
    padding: 40px;
    height: 100%;
    overflow: hidden;
    z-index: 1;
    background: #0b0b13;
    transition: ease background 0.35s;
    &:hover {
      background: #2fbf71;
      .icon {
        color: #fff;
      }
    }
    .icon {
      width: 60px;
      height: 60px;
      line-height: 60px;
      font-size: 40px;
      color: #fff;
      text-align: center;
    }
    .feature-content {
      padding-left: 25px;
    }
  }
}

.projects {
  .blog-grid {
    &:hover {
      .blog-img {
        img {
          transform: scale(1.1);
        }
      }
      .blog-info {
        a {
          background-size: 100% 2px;
        }
      }
    }
    .blog-img {
      overflow: hidden;
      img {
        transition: ease all 0.35s;
        transform: scale(1);
      }
    }
    .blog-info {
      background: #0b0b13;
      padding: 25px;
      margin: -80px 0 0 50px;
      position: relative;
      a {
        color: #fff;
        background-image: linear-gradient(130deg, #fff, #fff);
        background-position: 0% 100%;
        background-repeat: no-repeat;
        background-size: 0% 1px;
        transition: ease all 0.35s;
      }
      h6 {
        font-size: 24px;
        font-weight: 500;
        padding-top: 10px;
      }
    }
  }
}

.contact {
  padding-bottom: 300px;
  .contact-info {
    background: #0b0b13;
    padding: 30px;
    h4 {
      font-size: 28px;
      color: #fff;
      font-weight: 500;
      margin-bottom: 20px;
    }
    p {
      font-size: 16px;
    }
    ul {
      margin: 0;
      padding: 10px 0 0;
      list-style: none;
    }
    li {
      font-size: 18px;
      font-weight: 500;
      color: #fff;
      line-height: 1.3;
      position: relative;
      i {
        width: 30px;
        text-align: center;
        color: #2fbf71;
        font-size: 22px;
      }
      span {
        padding-left: 10px;
      }
      + li {
        padding-top: 20px;
      }
    }
  }
}

.color-switch {
  position: fixed;
  top: 25px;
  right: 25px;
  width: 50px;
  height: 50px;
  line-height: 50px;
  background: #fff;
  color: #0b0b13;
  text-align: center;
  font-size: 20px;
  border-radius: 50%;
  z-index: 1111;
  cursor: pointer;
}

.icon-mobile {
  font-family: "et-line";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  display: inline-block;
  &::before {
    content: "\e000";
  }
}

.loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #2fbf71;
  z-index: 99999;
}

.load-circle {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 50px;
  height: 50px;
}
.load-circle span {
  display: inline-block;
  width: 64px;
  height: 64px;
}
.load-circle span:after {
  content: " ";
  display: block;
  width: 46px;
  height: 46px;
  margin: 1px;
  border-radius: 50%;
  border: 5px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

.separated {
  padding-top: 80px;
  padding-bottom: 80px;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    height: 1px;
    background: #fff;
    opacity: 0.1;
  }
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1400px) {
  .container {
    width: 950px;
  }
}

@media (min-width: 768px) {
  .sidebar .nav-menu li .nav-link:hover {
    color: #fff;
  }
}

@media (max-width: 1200px) {
  .sidebar {
    transition: ease all 0.35s;
    left: -300px;
    z-index: 222;
    &.menu-open {
      left: 0;
    }
  }
  .navbar {
    display: block;
  }
  .color-switch {
    top: 71px;
    right: 13px;
  }
}

@media (min-width: 1201px) {
  .main-section {
    left: 250px;
    position: absolute;
    height: 100%;
    width: calc(100% - 250px);
  }
}

@media (max-width: 991px) {
  .separated {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .aducation-box {
    margin-bottom: 10px;
  }
  .resume-row {
    .rb-right {
      p {
        max-width: 100%;
      }
    }
  }
  .feature-box-01 {
    padding: 20px;
    .feature-content {
      padding-left: 15px;
    }
  }
}

@media (min-width: 768px) {
  .sidebar .nav-menu li .nav-link:hover {
    color: #fff;
  }
  .resume-row {
    .rb-left {
      border-right: 1px solid rgba(255, 255, 255, 0.05);
    }
  }
}

@media (max-width: 767px) {
  .section {
    padding-bottom: 60px;
  }
  .title {
    margin-bottom: 25px;
    h3 {
      letter-spacing: 2px;
    }
  }
  .separated {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .resume-row {
    .rb-time {
      position: relative;
      margin-bottom: 14px;
      vertical-align: top;
    }
  }
}
